export default {
  hlavicka: {
    type: 'form',
    values: [
      'partner:!ASCIIU(2,16)',
      'termin:!DATE',
      'typ:!ENUM(offerType)',
      'mena:!ENUM(mena)',
      'cislo_dosle:STRING(30)',
      'min_objem:UINT',
      'dodaci_lhuta:STRING',
      'poznamka:STRING'
    ],
    hasHeader: true,
    enums: {
      offerType: ['Stock', 'Preorder'],
      mena: ['CZK', 'EUR', 'USD']
    }
  },
  nabidka: {
    columns: [
      'ean:EAN',
      'nas_kod:PRODKOD',
      'kod_u_partnera:STRING(246)',
      'cena_mj:!UFLOAT',
      'velikost_baleni:UFLOAT',
      'note:STRING(256)'
    ],
    hasHeader: true,
    computed: {
      upper_kod_u_partnera: row => {
        return typeof row === 'string' ? row.kod_u_partnera.toUpperCase() : row.kod_u_partnera
      }
    }
  }
}
