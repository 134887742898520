<template>
	<div class="p-grid">
		<div class="p-col-8">
			<h1>Received Offer import</h1>
		</div>
		<div class="p-col-4" align="right">
			<a :href="`${publicPath}received_offer_import_template.xlsx`" class="p-button p-component" download>
				<span class="pi pi-download p-button-icon p-button-icon-left"></span>
				<span class="p-button-label">Received offer Import Template</span>
			</a>
		</div>
	</div>
	<div class="p-grid">
		<div class="p-col-12">

			<div class="card dropfile" v-if="status === 'empty'">
				<ProcessXLSX :rules="workbookRules" @parsed="workbookParsed" />
			</div>

			<div class="card" v-if="status === 'processing'">
				<p>Processing selected Excel Sheet</p>
				<ProgressBar :value="processingProgress" />
			</div>

			<div class="card" v-if="status === 'ready' || status === 'done'">
				<div class="p-grid">
					<div class="p-col-6">
						<h5 v-if="status === 'ready'">Received Offer - Excel file content</h5>
						<h5 v-if="status === 'done'"><b>Import done - results</b></h5>
					</div>
					<div class="p-col-6" align="right">
						<Button
							class="p-button-danger p-mr-2"
							icon="pi pi-times"
							label="Clear"
							@click="confirmClearing()"></Button>
						<Button
							v-if="status === 'ready'"
							class="p-button-success  p-mr-2"
							icon="pi pi-check"
							label="Import"
							@click="importData()"></Button>
						<Button
							v-if="status === 'done' || status === 'ready'"
							class="p-button-primary p-mr-2"
							icon="pi pi-download"
							label="Download errored rows"
							@click="downloadFailed()"></Button>
					</div>
				</div>
				<div>
					<h5>Header</h5>
					<ul class="list">
						<li>
							<div class="p-grid">
								<div class="p-col-3">Partner</div>
								<div class="p-col-8">{{workbook.hlavicka.partner}}</div>
							</div>
						</li>
						<li class="">
							<div class="p-grid">
								<div class="p-col-3">Termín platnosti</div>
								<div class="p-col-8">{{workbook.hlavicka.termin}}</div>
							</div>
						</li>
						<li class="">
							<div class="p-grid">
								<div class="p-col-3">Typ</div>
								<div class="p-col-8">{{workbook.hlavicka.typ}}</div>
							</div>
						</li>
						<li class="">
							<div class="p-grid">
								<div class="p-col-3">Měna</div>
								<div class="p-col-8">{{workbook.hlavicka.mena}}</div>
							</div>
						</li>
						<li class="">
							<div class="p-grid">
								<div class="p-col-3">Číslo nabídky dodavatele</div>
								<div class="p-col-8">{{workbook.hlavicka.cislo_dosle}}</div>
							</div>
						</li>
						<li class="">
							<div class="p-grid">
								<div class="p-col-3">Minimální objem</div>
								<div class="p-col-8">{{workbook.hlavicka.min_objem}}</div>
							</div>
						</li>
						<li class="">
							<div class="p-grid">
								<div class="p-col-3">Dodací lhůta</div>
								<div class="p-col-8">{{workbook.hlavicka.dodaci_lhuta}}</div>
							</div>
						</li>
						<li class="">
							<div class="p-grid">
								<div class="p-col-3">Poznámka</div>
								<div class="p-col-8">{{workbook.hlavicka.poznamka}}</div>
							</div>
						</li>
					</ul>
				</div>
				<div>
					<h5>Items</h5>
					<ImportOfferReceivedTable :workbook="workbook" @toggle-update="toggleUpdate" />
				</div>
			</div>

			<div class="card" v-if="status === 'importing'">
				<p>Importing data</p>
				<ProgressBar :value="importProgress" />
			</div>

			<Dialog
				header="Import Offer Received"
				v-model:visible="importDialog"
				:modal="true"
				:breakpoints="{'1200px': '75vw', '840px': '100vw'}"
				:style="{width: '60vw'}">
				<p>You are trying import Received Offer with {{workbook.nabidka.filter(it => it._status === 'OK').length}} items. Proceed?</p>
				<template #footer>
					<Button label="No" icon="pi pi-times" class="p-button-text" @click="importDialog = false"/>
					<Button label="Yes" icon="pi pi-check" autofocus @click="confirmImport()" />
				</template>
			</Dialog>
			<Dialog
				header="Error"
				v-model:visible="error.show"
				:modal="true"
				:breakpoints="{'1200px': '75vw', '840px': '100vw'}"
				:style="{width: '60vw'}">
				<p>{{error.title}}</p>
				<p>Details: {{error.detail}}</p>
				<template #footer>
					<Button label="Ok" autofocus @click="clear()" />
				</template>
			</Dialog>
			<ConfirmDialog></ConfirmDialog>
		</div>
	</div>
</template>

<script>

import wRules from '../service/xlsxParser/parseInRules/offerReceivedParseRules.js'
import FlexiAPI from '../service/flexiAPI/flexiAPI.js';
import XLSX from 'xlsx'

// Import components
import ProcessXLSX from '../components/ProcessXLSX.vue'
import {
	ImportOfferReceivedTable
} from '../components/import-offer-received/index.js'

export default {
	data() {
		return {
			workbookRules: wRules,
			workbook: {
				hlavicka: null,
				nabidka: null,
			},
			error: {
				show: false,
				detail: null,
				title: ''
			},
			cenik: null,
			partner: null,
			processingProgress: 0.0,
			status: 'empty',
			importDialog: false,
			errorDialog: false,
			publicPath: process.env.BASE_URL
		}
	},
	methods: {
		async workbookParsed(workbook) {

			this.workbook = workbook
			this.status = 'processing'

			this.processingProgress = 5.0

			try {
				if (!this.workbook || !this.workbook.hlavicka || !this.workbook.nabidka) {
					throw new Error('Unknwn Excel file. File must contain a sheet named \'Hlavička\' and \'Nabídka\'')
				}

				this.checkHlavicka()

				this.partner = await FlexiAPI.offerReceived.getPartnerWithCodes(workbook.hlavicka.partner)
				this.processingProgress = 25.0
				this.cenik = await FlexiAPI.offerReceived.getCenik()
			} catch (e) {
				console.log(e)
				this.showParseError(e)
				return
			}
			this.processingProgress = 90.0

			this.processWorkbook()
			this.processingProgress = 100.0

			this.status = 'ready'
		},
		checkHlavicka() {
			if (this.workbook.hlavicka._status === 'E') {
				throw this.workbook.hlavicka._errors[0]
			}
		},
		processWorkbook() {
			console.log('Workbook parsed')

			for (const row of this.workbook.nabidka) {
				const kodByKodIndi = this.partner.indiHashMap.get(row.kod_u_partnera)
				const prodByEan = this.cenik.byEan(row.ean)
				const prodByKod = this.cenik.byKod(row.nas_kod)
				const prodByKodIndi = this.cenik.byKod(kodByKodIndi)

				const prod = prodByEan || prodByKod || prodByKodIndi
				if (!prod) {
					row._status = 'E'
					row._errorType = 'unknown'
					row._errors.push(new Error(`Product ${row.ean}:${row.nas_kod}:${row.kod_u_partnera}' not found. Import it first.`))
					continue
				} else if ((prod !== prodByKod && prodByKod) || (prod !== prodByKodIndi && prodByKodIndi)) {
					row._status = 'E'
					row._errorType = 'ambiguous'
					row._errors.push(new Error(`Product ${row.ean}:${row.nas_kod}:${row.kod_u_partnera}' is ambiguous.`))
					continue
				}

				row._flexiKod = prod.kod
			}

			console.log('Workbook processing done')
		},
		showParseError(e) {
			this.error.title = 'Excel sheet with Received offer parsing failed with following error.'
			this.error.detail = e.toString()
			this.error.show = true
		},
		showImportError(e) {
			this.error.title = 'Received offer import failed with following error.'
			this.error.detail = e.toString()
			this.error.show = true
		},
		clear() {
			this.workbook.hlavicka = null
			this.workbook.nabidka = null
			this.error.show = false
			this.error.detail = null
			this.error.title = ''
			this.cenik = null
			this.partner = null
			this.processingProgress = 0.0
			this.status = 'empty'
			this.importDialog = false
			this.errorDialog = false
		},
		confirmClearing() {
			this.$confirm.require({
				message: 'Are you sure you want to proceed? All changes will be lost.',
				header: 'Clear loaded file',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.clear()
				}
			})
		},
		importData() {
			this.importDialog = true

		},
		async confirmImport() {
			this.importDialog = false
			this.status = 'importing'

			// Import Offer at ones
			const minobj = this.workbook.hlavicka.min_objem || 'nestanoven'
			const dodlhut = this.workbook.hlavicka.dodaci_lhuta || 'neznámá'
			const typDokl = this.workbook.hlavicka.typ === 'Stock'
				? FlexiAPI.OFR_TYPE.REC.STOCK : FlexiAPI.OFR_TYPE.REC.PREORD
			const offer = {
				cisDosle: this.workbook.hlavicka.cislo_dosle,
				datTermin: this.workbook.hlavicka.termin,
				poznam: `Minimální objem: ${minobj}\nDodací lhůta: ${dodlhut}\n\n${this.workbook.hlavicka.poznamka}`,
				typDokl: typDokl,
				mena: this.workbook.hlavicka.mena,
				firma: this.partner.kod,
				polozkyDokladu: []
			}

			for (let row of this.workbook.nabidka.filter(r => r._status === 'OK')) {
				const item = {
					cenik: row._flexiKod,
					cenaMj: row.cena_mj,
					poznam: row.poznam,
					objem: row.velikost_baleni
				}
				offer.polozkyDokladu.push(item)
			}

			try {
				const res = await FlexiAPI.offerReceived.post(offer)
			} catch (e) {
				this.showImportError(e)
				return
			}

			this.status = 'done';
		},

		downloadFailed() {
			const wb = XLSX.utils.book_new()

			const entries = this.workbook.nabidka.filter(it => it._status === 'E')

			if (entries.length) {
				const cenikWs = XLSX.utils.aoa_to_sheet([['EAN', 'Náš Kód', 'Kód u Partnera', 'Chyba párování', 'Chyba'],
				...entries.map(prd => {
					return [
						prd.ean ? prd.ean.toString() : null,
						prd.nas_kod,
						prd.kod_u_partnera,
						prd._errorType,
						prd._errors.join('\n')
					]
				})])
				XLSX.utils.book_append_sheet(wb, cenikWs, "Nabídka")
			}

			const date = (new Date()).toISOString().replace('T', '_').replace(':','-').replace(':', '-').substring(0,19)
			XLSX.writeFile(wb, `${date}_failed_match_katalog_nabidka_prijata.xlsx`);
		},

		toggleUpdate(row) {
			row._selected = !row._selected
		}
	},
	components: {
		ProcessXLSX,
		ImportOfferReceivedTable
	}
}
</script>

<style scoped>

a.p-button {
	cursor: pointer;
}

h1 {
	font-size: 2rem;
}

</style>
